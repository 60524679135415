import { Button } from 'easyship-components'
// import { Exclaim } from 'easyship-components/icons'

import ScanInput from '@/components/ScanInput'
import { usePackage } from '@/pages/PackagePage/PackageProvider'

export default function RepackSection() {
  const { pack, requestRepack, skipRepack } = usePackage()

  function handleScanChange(upc: string) {
    requestRepack.mutate({ packageId: pack.id, upc })
  }
  function handleSkipRepack() {
    skipRepack.mutate()
  }

  return (
    <>
      <div className="mb-5">
        <div className="mb-3 flex items-center">
          {/* <Exclaim className="mr-2 fill-red-900" /> */}
          <div className="text-xl font-bold text-ink-500">
            If this package was repacked, scan the UPC barcode
          </div>
        </div>
        <ScanInput placeholder="Scan UPC" onChange={handleScanChange} autoFocus />
      </div>
      {pack.warehouseState.value !== 'courier_returned' && (
        <Button className="h-20 w-full" onClick={handleSkipRepack}>
          Skip Repack
        </Button>
      )}
    </>
  )
}
