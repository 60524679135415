import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'

import Button from '@/components/Button'
import { useSession } from '@/contexts/session'
import { PackageMeasurements, PackageMeasurementsMetadata } from '@/core/models/Package'

import InductionResult, { InductionPassResult } from './models/Induction'
import useGetPackageMeasurementsQuery from './useGetPackageMeasurementsQuery'
import useInductions from './useInductions'
import WeightDimensionsForm, {
  WeightDimensionsEditFormData,
  defaultMeasureFormData,
  WeightDimensionsFormData,
} from './WeightDimensionsForm'

const MEASUREMENT_API_CALL_DELAY_MS = 300

enum ExtremePackageSize {
  'TOO_SMALL' = 'too small',
  'TOO_LARGE' = 'too large',
}

interface WeightDimensionsMeasureFormProps {
  inductionResult: InductionPassResult | null
  onSubmitSuccess(inductionResult: InductionResult): void
}

export default function WeightDimensionsMeasureForm({
  inductionResult,
  onSubmitSuccess,
}: WeightDimensionsMeasureFormProps) {
  const { session } = useSession()
  const measurePackageQuery = useGetPackageMeasurementsQuery()
  const queryClient = useQueryClient()
  const { measureWeightDimensionsMutation, markAsUndersizedMutation, markAsOversizedMutation } =
    useInductions()
  const [formData, setFormData] = useState<WeightDimensionsEditFormData>(defaultMeasureFormData)
  const [extremePackageSize, setExtremePackageSize] = useState<ExtremePackageSize | null>(null)
  const packageMeasurementsMetadataRef = useRef<PackageMeasurementsMetadata>()
  const hasExtremePackageSizeSelected = extremePackageSize !== null
  const isSubmitButtonEnabled = isFormValid(formData) || hasExtremePackageSizeSelected
  const isSubmitButtonLoading =
    measureWeightDimensionsMutation.isLoading ||
    markAsUndersizedMutation.isLoading ||
    markAsOversizedMutation.isLoading
  const isStationTooSmall =
    session?.stationId === 2222 || session?.stationId === 49 || session?.stationId === 151

  function isFormValid(
    formData: WeightDimensionsEditFormData,
  ): formData is WeightDimensionsFormData {
    return Object.values(formData).every((field) => !!field && field > 0)
  }

  useEffect(() => {
    if (extremePackageSize) return
    const timeoutId = setTimeout(getPackageMeasurements, MEASUREMENT_API_CALL_DELAY_MS)
    return () => clearTimeout(timeoutId)
  }, [extremePackageSize])

  function resetFormData(formData?: Partial<PackageMeasurements>) {
    setFormData({
      weight: formData?.weight?.value ?? '',
      length: formData?.dimensions?.length ?? '',
      width: formData?.dimensions?.width ?? '',
      height: formData?.dimensions?.height ?? '',
    })
  }

  function getPackageMeasurements() {
    resetFormData()
    if (extremePackageSize) return
    measurePackageQuery.refetch().then(({ data, isError }) => {
      if (isError || !data) return
      resetFormData(data)
      packageMeasurementsMetadataRef.current = {
        measurementId: data.measurementId,
        scannedOn: data.scannedOn,
      }
      if (!inductionResult) return
      measureWeightDimensionsMutation.mutate(
        {
          packageId: inductionResult.package.id,
          formData: {
            weight: data.weight.value,
            length: data.dimensions.length,
            width: data.dimensions.width,
            height: data.dimensions.height,
          },
          metadata: packageMeasurementsMetadataRef.current,
        },
        { onSuccess: (res) => onSubmitSuccess(res) },
      )
    })
  }

  function handleExtremePackageSizeChange(
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ExtremePackageSize | null,
  ) {
    queryClient.cancelQueries({ queryKey: ['inductionPackageMeasurements'] })
    setExtremePackageSize(value)
    resetFormData()
  }

  function handleSubmit() {
    if (!inductionResult) return

    if (extremePackageSize === ExtremePackageSize.TOO_SMALL) {
      markAsUndersizedMutation.mutate(inductionResult.package.id, {
        onSuccess: () => onSubmitSuccess(inductionResult),
      })
    } else if (extremePackageSize === ExtremePackageSize.TOO_LARGE) {
      markAsOversizedMutation.mutate(inductionResult.package.id, {
        onSuccess: () =>
          onSubmitSuccess({ ...inductionResult, status: 'oversize', label: 'Oversize' }),
      })
    } else {
      isFormValid(formData) &&
        measureWeightDimensionsMutation.mutate(
          {
            packageId: inductionResult.package.id,
            formData,
            metadata: packageMeasurementsMetadataRef.current,
          },
          { onSuccess: (res) => onSubmitSuccess(res) },
        )
    }
  }

  return (
    <Stack spacing={2}>
      <WeightDimensionsForm
        value={formData}
        onChange={setFormData}
        loading={measurePackageQuery.isFetching}
      />
      <Button
        sx={{ alignSelf: 'flex-start' }}
        onClick={getPackageMeasurements}
        disabled={hasExtremePackageSizeSelected || measurePackageQuery.isFetching}
      >
        re-measure package
      </Button>
      <Typography variant="h4">
        If there’s no reading, leave the above blank and mark package as:
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <ToggleButtonGroup
          color="primary"
          value={extremePackageSize}
          exclusive
          onChange={handleExtremePackageSizeChange}
          aria-label="Extreme package size"
        >
          {Object.entries(ExtremePackageSize).map(([key, value]) => (
            <ToggleButton
              disabled={value === ExtremePackageSize.TOO_SMALL && !isStationTooSmall}
              key={key}
              sx={{ height: 80, minWidth: 150 }}
              value={value}
            >
              {value}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          disabled={!isSubmitButtonEnabled}
          sx={{ height: 80, minWidth: 150 }}
          loading={isSubmitButtonLoading}
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  )
}
