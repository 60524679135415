import Link from '@mui/material/Link'

import { PackageItem } from '@/core/models/Package'

interface ItemsListProps {
  items: PackageItem[]
}

export default function ItemsList({ items }: ItemsListProps) {
  return (
    <ol style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
      {items.map((item) => (
        <li key={item.ebayItemId}>
          <Link href={item.url} target="_blank" rel="noopener">
            {item.description}
          </Link>{' '}
          x{item.quantity}
          <div>Item ID: {item.sku || '-'}</div>
        </li>
      ))}
    </ol>
  )
}
