import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import {
  BatteryInspectionResult,
  BatteryUnshippableReason,
} from '../../gateways/api/models/Package'
import Collapse from './Collapse'
import { usePackage } from './PackageProvider'

export const batteryUnshippableReasonLabel: Record<BatteryUnshippableReason, string> = {
  not_secure_from_movement: 'not secure from movement',
  not_secure_from_activation: 'not secure from activation',
  classification: 'classification',
  overweight: 'overweight',
}

const inspectionResultTitle: Record<Exclude<BatteryInspectionResult, 'no_batteries'>, string> = {
  shippable: 'Shippable',
  shippable_with_labels: 'Shippable with Stickers',
  shippable_with_stickers: 'Shippable with Stickers',
  unshippable: 'Not Shippable',
}

export default function BatteryInspectionOutcome() {
  const { pack } = usePackage()

  if (pack.batteryInspectionOutcome.result === 'no_batteries') return null

  const hasBatteryPackagingInstructions = pack.batteryInspectionOutcome.classifications.some(
    (classification) => classification.instructions.length > 0,
  )
  const hasClassifications = pack.batteryInspectionOutcome.classifications.length > 0

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <BatteryChargingFullIcon />
        <Typography variant="h4">
          Contains Batteries - {inspectionResultTitle[pack.batteryInspectionOutcome.result]}
        </Typography>
      </Stack>
      <Stack spacing={2} style={{ marginLeft: 30 }}>
        {pack.batteryInspectionOutcome.unshippableReasons.length > 0 && (
          <Typography textTransform="uppercase">
            Reasons:{' '}
            {pack.batteryInspectionOutcome.unshippableReasons
              .map((reason) => batteryUnshippableReasonLabel[reason])
              .join(', ')}
          </Typography>
        )}
        {hasClassifications && (
          <div>
            <Typography textTransform="uppercase">Classification(s)</Typography>
            {pack.batteryInspectionOutcome.classifications.map((classification, index) => (
              <Typography key={index} variant="h2" sx={{ mt: 1 }}>
                {classification.name}
              </Typography>
            ))}
          </div>
        )}
        <Stack divider={<Divider light />} spacing={1}>
          {hasBatteryPackagingInstructions && (
            <Collapse
              title="Instructions - Battery Packaging"
              defaultOpen={pack.warehouseState.value === 'battery_confirmed_compliant'}
            >
              <Stack spacing={1}>
                {pack.batteryInspectionOutcome.classifications.map((classification, index) => (
                  <div key={index}>
                    <Typography fontWeight="bold" sx={{ mt: 1 }}>
                      {classification.name}:
                    </Typography>
                    <ol style={{ paddingLeft: 0, margin: 0, marginLeft: '1em' }}>
                      {classification.instructions.map((instruction, index) => (
                        <li key={index}>{instruction}</li>
                      ))}
                    </ol>
                  </div>
                ))}
              </Stack>
            </Collapse>
          )}
          {pack.batteryInspectionOutcome.shippingDocumentInstructions.length > 0 && (
            <Collapse
              title="Instructions - Shipping Documents"
              defaultOpen={pack.warehouseState.value === 'ready_for_xray'}
            >
              <ol style={{ padding: 0, margin: 0, marginLeft: '1em' }}>
                {pack.batteryInspectionOutcome.shippingDocumentInstructions.map(
                  (instruction, index) => (
                    <li key={index}>{instruction}</li>
                  ),
                )}
              </ol>
            </Collapse>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
