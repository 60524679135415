import axios, { AxiosResponse } from 'axios'

import Package, { PackageMeasurements, PackageMeasurementsMetadata } from '@/core/models/Package'
import { Repack } from '@/core/models/Repack'
import PackagesGateway from '@/core/ports/packages'
import { MeasureFormData } from '@/pages/PackagePage/MeasureForm'
import { BatteryInspectionFormData } from '@/pages/PackagePage/PackageInspection/BatteryInspection'
import { SearchFormData } from '@/pages/ScanPage/ScanPage'
import { mapFromApi as mapReturnPackageFromApi } from '@/returnPackage/gateways/api/mappers/returnPackageMapper'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'
import { OrderSearchPayload } from '@/searchResult/gateways/api/models/Order'

import { mapToApi as mapMeasureToApi } from './mappers/measureMapper'
import { mapFromApi as mapPackageFromApi } from './mappers/packageMapper'
import { mapRepackFromApi } from './mappers/RepackMapper'
import {
  PackageResponse,
  MeasurePayload,
  ConfirmFlagPayload,
  DismissFlagPayload,
  BatteryInspectionPayload,
  BatteryCellPayload,
  MarkAsReceivedPayload,
  InspectionSource,
  PlaceInBinPayload,
  ScanResponse,
  MeasurementsResponse,
} from './models/Package'
import { RepackResponse, RepackType } from './models/Repack'

function scan(barcode: string): Promise<(Package | ReturnPackage)[]> {
  return axios
    .get<ScanResponse>(`/v1/scan/${barcode}`)
    .then(({ data }) =>
      data.result.data.map((res) =>
        res.type === 'Package'
          ? mapPackageFromApi(res.attributes)
          : mapReturnPackageFromApi(res.attributes),
      ),
    )
}

function markAsReceived(packageId: string, searchParams: SearchFormData): Promise<Package> {
  const searchFieldMap: Record<keyof SearchFormData, keyof OrderSearchPayload> = {
    evtn: 'evtn',
    sellerFirstName: 'seller_first_name',
    sellerLastName: 'seller_last_name',
    buyerFirstName: 'buyer_first_name',
    buyerLastName: 'buyer_last_name',
  }
  const entries = Object.entries(searchParams) as [keyof SearchFormData, string][]
  const usedFields = entries.reduce<(keyof OrderSearchPayload)[]>(
    (keys, [key, value]) => (value ? [...keys, searchFieldMap[key]] : keys),
    [],
  )

  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, MarkAsReceivedPayload>(
      `/v1/packages/${packageId}/mark_as_received`,
      { search_fields: usedFields },
    )
    .then(({ data }) => mapPackageFromApi(data.package))
}

function measureWeightDimensions(
  packageId: string,
  formData: MeasureFormData,
  metadata: PackageMeasurementsMetadata | undefined,
): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, MeasurePayload>(
      `/v1/packages/${packageId}/measure`,
      mapMeasureToApi(formData, metadata),
    )
    .then(({ data }) => mapPackageFromApi(data.package))
}

function requestLabel(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/request_label`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function printLabel(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/print_label`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function requireInspection(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/require_inspection`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function requireDestroyLiquidate(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/require_destroy_liquidate`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function forceDestroyLiquidate(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/force_destroy_liquidate`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function dismissFlag(packageId: string, payload: DismissFlagPayload): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, DismissFlagPayload>(
      `/v1/packages/${packageId}/dismiss_flag`,
      payload,
    )
    .then(({ data }) => mapPackageFromApi(data.package))
}

function confirmFlag(packageId: string, payload: ConfirmFlagPayload): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, ConfirmFlagPayload>(
      `/v1/packages/${packageId}/confirm_flag`,
      payload,
    )
    .then(({ data }) => mapPackageFromApi(data.package))
}

function submitBatteryInspection(
  packageId: string,
  formData: BatteryInspectionFormData,
): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, BatteryInspectionPayload>(
      `/v1/packages/${packageId}/classify_battery`,
      {
        forms: formData.batteryFormsData.map((data) => ({
          item_id: data.itemId,
          item_description: data.itemDescription,
          battery_present: data.batteryPresent,
          battery_classification: data.classification || null,
          battery_classification_comment: data.classificationComment || null,
          requires_compliance_review: data.requiresComplianceReview ?? null,
          battery_packaging: data.packing || null,
          battery_type: data.type || null,
          batteries_or_cells: data.batteriesCells.map<BatteryCellPayload>((batteryCell) => ({
            lithium_content: batteryCell.lithiumContent || null,
            watt_hours: batteryCell.wattHours || null,
            total_weight: batteryCell.totalWeight.value,
            weight_unit: batteryCell.totalWeight.unit,
          })),
          is_equipment_and_battery_secured_from_movement: data.movementSecured ?? null,
        })),
        is_parcel_equipped_to_prevent_accidental_activation: formData.doesPreventAccident ?? null,
      },
    )
    .then(({ data }) => mapPackageFromApi(data.package))
}

function markForDestruction(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/mark_for_destruction`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function markForLiquidation(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/mark_for_liquidation`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function printBarcode(packageId: string): Promise<void> {
  return axios.post(`/v1/packages/${packageId}/print_barcode`)
}

function reprintLabel(packageId: string): Promise<void> {
  return axios.post(`/v1/packages/${packageId}/reprint_label`)
}

function requestInspection(packageId: string, source: InspectionSource): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/request_inspection`, { source })
    .then(({ data }) => mapPackageFromApi(data.package))
}

function placeInBin(packageId: string, barcode: string): Promise<void> {
  return axios.post<void, void, PlaceInBinPayload>(`/v1/packages/${packageId}/place_in_bin`, {
    barcode,
  })
}

function getPackageMeasurements(): Promise<PackageMeasurements> {
  return axios
    .get<MeasurementsResponse>('/v1/dimensioner/measurements')
    .then(({ data: { measurements } }) => ({
      weight: {
        value: measurements.weight,
        unit: measurements.weight_unit,
      },
      dimensions: {
        length: measurements.length,
        width: measurements.width,
        height: measurements.height,
        unit: measurements.dimension_unit,
      },
      measurementId: measurements.metadata.measurement_id,
      scannedOn: measurements.metadata.scanned_on,
    }))
}

function resetToMeasureStep(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/re_measure`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function markAsOverLimit(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/mark_as_over_limit`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function markAsCourierReturn(packageId: string): Promise<Package> {
  return axios
    .post<PackageResponse>(`/v1/packages/${packageId}/mark_as_courier_returned`)
    .then(({ data }) => mapPackageFromApi(data.package))
}

function requestRepack(packageId: string, upc: RepackType): Promise<Repack> {
  return axios
    .post<RepackResponse>(`/v1/packages/${packageId}/repacks`, {
      upc,
    })
    .then(({ data }) => mapRepackFromApi(data.repack))
}
function skipRepack(packageId: string): Promise<void> {
  return axios.post(`/v1/packages/${packageId}/skip_repack`)
}

function submitZendeskTickets(packageId: string, zendeskTicketId: string): Promise<void> {
  return axios.post(`/v1/packages/${packageId}/zendesk_tickets`, {
    zendesk_ticket_id: zendeskTicketId,
  })
}

export default function createApiPackagesGateway(): PackagesGateway {
  return {
    scan,
    markAsReceived,
    measureWeightDimensions,
    requestLabel,
    printLabel,
    requireInspection,
    requireDestroyLiquidate,
    dismissFlag,
    confirmFlag,
    submitBatteryInspection,
    markForDestruction,
    markForLiquidation,
    printBarcode,
    reprintLabel,
    requestInspection,
    placeInBin,
    getPackageMeasurements,
    resetToMeasureStep,
    markAsOverLimit,
    forceDestroyLiquidate,
    markAsCourierReturn,
    requestRepack,
    skipRepack,
    submitZendeskTickets,
  }
}
