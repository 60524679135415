import Package, { BatteryInspectionOutcome } from '../../../core/models/Package'
import { BatteryInspectionOutcomeResponse, PackageDataResponse } from '../models/Package'

export function mapFromApi(pack: PackageDataResponse): Package {
  return {
    id: pack.id,
    ebayPackageId: pack.ebay_package_id,
    easyshipShipmentId: pack.easyship_shipment_id,
    lastStationId: pack.last_station_id,
    scanReceived: pack.scan_received,
    firstMileTrackingNumber: pack.first_mile_tracking_number,
    lastMileTrackingNumber: pack.last_mile_tracking_number ?? '',
    combinedShipment: pack.combined_shipment,
    items: pack.items.map((item) => ({
      ebayItemId: item.ebay_item_id,
      description: item.description,
      url: item.listing_url,
      quantity: item.quantity,
      sku: item.sku ?? '',
    })),
    warehouseState: { value: pack.warehouse_state, displayName: pack.warehouse_state_display_name },
    lastMileCourierName: pack.last_mile_courier_name ?? '',
    flaggedFor: pack.flagged_for ?? [],
    screeningFlags: (pack.screening_flags ?? []).map((screeningFlag) => ({
      category: screeningFlag.category,
      keyword: screeningFlag.keyword ?? '',
      comments: screeningFlag.comments,
      guidelines: screeningFlag.screening_guidelines ?? '',
      operatingProcedures: screeningFlag.operating_procedures ?? '',
      status: screeningFlag.status,
    })),
    inspections: pack.inspections,
    batteryInspectionOutcome: mapBatteryInspectionOutcome(pack.battery_inspection_outcome),
    labelState: pack.label_state,
    evtn: pack.evtn,
    senderName: pack.ship_from.full_name ?? '',
    receiverAddress: {
      fullName: pack.ship_to.full_name ?? '',
      addressLine1: pack.ship_to.address_line_1 ?? '',
      addressLine2: pack.ship_to.address_line_2 ?? '',
      city: pack.ship_to.city ?? '',
      state: pack.ship_to.state ?? '',
      postalCode: pack.ship_to.postal_code ?? '',
      countryCode: pack.ship_to.country_code ?? '',
      country: pack.ship_to.country ?? '',
    },
    bin: pack.bin_to_place_in
      ? {
          barcode: pack.bin_to_place_in.barcode,
          name: pack.bin_to_place_in.name,
          displayName: pack.bin_to_place_in.display_name,
          type: pack.bin_to_place_in.type,
        }
      : null,
    weight: { value: pack.weight?.value ?? 0, unit: pack.weight?.unit ?? 'lb' },
    dimensions: {
      length: pack.dimensions?.length ?? 0,
      width: pack.dimensions?.width ?? 0,
      height: pack.dimensions?.height ?? 0,
      unit: pack.dimensions?.unit ?? 'in',
    },
    courierReturn: pack.courier_return
      ? {
          id: pack.courier_return.id,
          packageId: pack.courier_return.package_id,
          returnType: pack.courier_return.return_type,
          returnReason: pack.courier_return.return_reason,
          packageAction: pack.courier_return.package_action,
          createdAt: pack.courier_return.created_at,
          updatedAt: pack.courier_return.updated_at,
          processed_at: pack.courier_return.processed_at,
          contentDamaged: pack.courier_return.content_damaged,
          packagingDamaged: pack.courier_return.packaging_damaged,
          label: pack.courier_return.label
            ? {
                id: pack.courier_return.label.id,
                active: pack.courier_return.label.active,
                alternateTrackingNumber: pack.courier_return.label.alternate_tracking_number,
                courierAdminName: pack.courier_return.label.courier_admin_name,
                courierId: pack.courier_return.label.courier_id,
                courierName: pack.courier_return.label.courier_name,
                courierUmbrellaName: pack.courier_return.label.courier_umbrella_name,
                createdAt: pack.courier_return.label.created_at,
                packageId: pack.courier_return.label.package_id,
                state: pack.courier_return.label.state,
                trackingNumber: pack.courier_return.label.tracking_number,
                updatedAt: pack.courier_return.label.updated_at,
              }
            : null,
          labelUsable: pack.courier_return.label_usable,
        }
      : null,
    repack: pack.repack
      ? {
          id: pack.repack.id,
          ebayPackingMaterialId: pack.repack.ebay_packing_material_id,
          packageId: pack.repack.package_id,
          height: pack.repack.height,
          width: pack.repack.width,
          length: pack.repack.length,
          unit: pack.repack.unit,
          typeOf: pack.repack.type_of,
          reason: pack.repack.reason,
          upc: pack.repack.upc,
        }
      : null,
    multipack: pack.multipack,
    packageTags: pack.package_tags,
  }
}

function mapBatteryInspectionOutcome(
  battery_inspection_outcome: BatteryInspectionOutcomeResponse | null,
): BatteryInspectionOutcome {
  return {
    result: battery_inspection_outcome?.result ?? 'no_batteries',
    shippingDocumentInstructions: battery_inspection_outcome?.shipping_document_instructions ?? [],
    stickers: battery_inspection_outcome?.labels ?? [],
    unshippableReasons: battery_inspection_outcome?.unshippable_reasons ?? [],
    classifications: battery_inspection_outcome?.classifications ?? [],
  }
}
