import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import COLORS from '@/colors'
import Stack from '@/components/Stack'
import { Stat, StatLabel, StatValue } from '@/components/Stat'

import PreInspectionPackage from './models/PreInspection'

interface PackageDetailsProps {
  pack?: PreInspectionPackage
}

export default function PreInspectionPackageDetails({ pack }: PackageDetailsProps) {
  return (
    <Stack direction="row">
      <Box flex={1} gap={1}>
        <Stat horizontal>
          <StatLabel variant="h3">Package ID</StatLabel>
          <StatValue variant="h4">{pack?.easyshipShipmentId}</StatValue>
        </Stat>
        <Stat horizontal>
          <StatLabel variant="h3">Items</StatLabel>
          <StatValue variant="h4">
            {pack?.items?.map((item, index) => (
              <ol key={index} style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
                <li key={item.ebayItemId}>
                  <Link
                    href={item.listingUrl}
                    target="_blank"
                    rel="noopener"
                    color={COLORS.blueNormal}
                  >
                    {item.description}
                  </Link>{' '}
                  x{item.quantity}
                </li>
              </ol>
            ))}
          </StatValue>
        </Stat>
      </Box>
    </Stack>
  )
}
