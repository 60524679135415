import InputAdornment from '@mui/material/InputAdornment'
import { useEffect, useState } from 'react'

import useDebounce from '@/hooks/useDebounce'

import ScanIcon from './icons/ScanIcon'
import Input, { InputProps } from './Input'

interface ScanInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: string) => void
}

export default function ScanInput({ onChange, ...props }: ScanInputProps) {
  const [scanValue, setScanValue] = useState('')
  const scanValueDebounced = useDebounce(scanValue, 300)

  useEffect(() => {
    if (scanValueDebounced) {
      onChange?.(scanValueDebounced)
      setScanValue('')
    }
  }, [scanValueDebounced])

  return (
    <Input
      data-hj-allow
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ScanIcon />
          </InputAdornment>
        ),
      }}
      value={scanValue}
      onChange={(e) => setScanValue(e.currentTarget.value)}
      {...props}
    />
  )
}
