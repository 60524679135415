import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { Button, Chip } from 'easyship-components'
import { useLocation, useNavigate } from 'react-router-dom'

import ItemsList from '@/components/ItemsList'
import { Stat, StatLabel, StatValue } from '@/components/Stat'

import Package from '../../core/models/Package'
import { NavigationStateParams } from './PackagesSelectionPage'

interface PackageInfoDataProps {
  idx: number
  pack: Package
}

interface PackageDataItem {
  label: string
  value: React.ReactNode
  isHighlight: boolean
}

function PackageInfo({ pack, idx }: PackageInfoDataProps) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams

  const packageData: PackageDataItem[] = [
    {
      label: 'Easyship Shipment ID',
      value: pack.easyshipShipmentId,
      isHighlight: false,
    },
    {
      label: 'Warehouse State',
      value: pack.warehouseState.displayName || '-',
      isHighlight: true,
    },
    {
      label: 'Scan Received',
      value: dayjs(pack.scanReceived).format('DD MMM YYYY, h:mm A'),
      isHighlight: false,
    },
    {
      label: 'Warehouse State',
      value: pack.warehouseState.displayName || '-',
      isHighlight: true,
    },
    {
      label: 'Scan Received',
      value: dayjs(pack.scanReceived).format('DD MMM YYYY, h:mm A'),
      isHighlight: false,
    },

    {
      label: 'First Mile Tracking No.',
      value: pack.firstMileTrackingNumber || '-',
      isHighlight: false,
    },
    {
      label: 'Last Mile Tracking No.',
      value: pack.lastMileTrackingNumber || '-',
      isHighlight: false,
    },
    {
      label: 'Last Station ID',
      value: pack.lastStationId || '-',
      isHighlight: false,
    },
  ]

  return (
    <div className="rounded-sm border border-sky-500 bg-sky-300 px-4 py-5">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '85px repeat(3, 1fr) 120px',
          gridGap: 20,
          gridTemplateAreas: `
            "package . . . action"
            "chip . . . action"
          `,
        }}
      >
        <Stack sx={{ gridArea: 'package' }}>
          <Typography variant="subtitle2" fontWeight="bold">
            Package
          </Typography>
          <Typography variant="subtitle1">#{idx}</Typography>
        </Stack>
        <Stack sx={{ gridArea: 'chip' }}>
          <Chip className="text-base" color={pack.combinedShipment ? 'yellow' : 'teal'}>
            {pack.combinedShipment ? 'Combined' : 'Regular'}
          </Chip>
        </Stack>

        {packageData.map((pack) => (
          <Stat key={pack.label}>
            <StatLabel>{pack.label}</StatLabel>
            <StatValue variant="h3" {...(pack.isHighlight && { color: 'blue.dark' })}>
              {pack.value}
            </StatValue>
          </Stat>
        ))}

        <Stack justifyContent="center" sx={{ gridArea: 'action' }}>
          <Button
            onClick={() =>
              navigate('/package', {
                state: { package: pack, searchParams: navigationState?.searchParams },
              })
            }
            color="primary"
            className="h-[80px] "
          >
            select
          </Button>
        </Stack>
      </Box>
    </div>
  )
}

export default function PackageInfoData({ idx, pack }: PackageInfoDataProps) {
  const { evtn, senderName, receiverAddress, items } = pack
  const shipToAddress = [
    receiverAddress.addressLine1,
    receiverAddress.addressLine2,
    receiverAddress.city,
    receiverAddress.state,
    receiverAddress.postalCode,
    receiverAddress.countryCode,
  ]
    .filter((v) => !!v)
    .join(', ')

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '146px 260px 1fr',
          gridGap: 20,
          mb: 2,
        }}
      >
        <Stat>
          <StatLabel>EVTN</StatLabel>
          <StatValue variant="h1" color="blue.dark">
            {evtn}
          </StatValue>
        </Stat>

        <Stack spacing={1.25}>
          <Stat>
            <StatLabel>Items</StatLabel>
            <ItemsList items={items} />
          </Stat>
        </Stack>

        <Stack spacing={1.25}>
          <Stat horizontal>
            <StatLabel>Ship From - Name</StatLabel>
            <StatValue variant="h5">{senderName}</StatValue>
          </Stat>
          <Stat horizontal>
            <StatLabel>Ship To - Name</StatLabel>
            <StatValue variant="h5">{receiverAddress.fullName}</StatValue>
          </Stat>

          <Stat horizontal>
            <StatLabel>Ship To - Address</StatLabel>
            <StatValue variant="h5">{shipToAddress}</StatValue>
          </Stat>

          <Stat horizontal>
            <StatLabel>Ship To - Country</StatLabel>
            <StatValue variant="h5">{receiverAddress.country}</StatValue>
          </Stat>
        </Stack>
      </Box>
      <PackageInfo pack={pack} idx={idx} />
    </>
  )
}
