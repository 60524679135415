import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import ErrorIcon from '@mui/icons-material/Error'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiCollapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import { usePackage } from '../PackageProvider'
import BatteryForm, { BatteryEditFormData, BatteryFormData } from './BatteryForm'
import YesNoSelect from './YesNoSelect'

interface CollapseHeaderProps {
  title: string
  hasBattery: boolean | undefined
  formCompleted: boolean
}

function ItemCollapseHeader({ title, hasBattery, formCompleted }: CollapseHeaderProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <span>{title}</span>
      <Stack direction="row">
        {typeof hasBattery === 'boolean' && (
          <Box
            data-testid={hasBattery ? 'has-battery' : 'has-no-battery'}
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={hasBattery ? 'initial' : 'sky.dark'}
            width={50}
          >
            <BatteryChargingFullIcon sx={{ position: 'absolute' }} />
            {!hasBattery && <DoDisturbIcon fontSize="large" />}
          </Box>
        )}
        <Stack>
          <Typography variant="h5">Status</Typography>
          {formCompleted ? 'Completed' : 'Incomplete'}
        </Stack>
      </Stack>
    </Stack>
  )
}

interface BatteryInspectionEditFormData {
  batteryFormsData: BatteryFormData[]
  doesPreventAccident: boolean | undefined
}

export interface BatteryInspectionFormData {
  batteryFormsData: BatteryFormData[]
  doesPreventAccident: boolean | undefined
}

export default function BatteryInspection() {
  const { pack, currentInspection, submitBatteryInspection, classifyBattery } = usePackage()
  const [batteryInspectionFormData, setBatteryInspectionFormData] =
    useState<BatteryInspectionEditFormData>({
      batteryFormsData: [],
      doesPreventAccident: undefined,
    })
  const [itemsBatteryPresent, setItemsBatteryPresent] = useState<(boolean | undefined)[]>([])

  function handleFormChange(index: number, formData: BatteryEditFormData) {
    const newData = [...itemsBatteryPresent]
    newData[index] = formData.batteryPresent
    setItemsBatteryPresent(newData)
  }

  function handleFormSubmit(index: number, formData: BatteryFormData) {
    const newData = [...batteryInspectionFormData.batteryFormsData]
    newData[index] = formData
    setBatteryInspectionFormData({ ...batteryInspectionFormData, batteryFormsData: newData })
  }

  const openFormIndex = batteryInspectionFormData.batteryFormsData.length
  const areAllItemFormsCompleted = openFormIndex === pack.items.length
  const hasPackageBattery = batteryInspectionFormData.batteryFormsData.some(
    (item) => item.batteryPresent
  )
  const isPreventAccidentFieldSet = batteryInspectionFormData.doesPreventAccident !== undefined

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <ErrorIcon color="error" />
        <Typography variant="h1">Complete Battery Handling Form</Typography>
      </Stack>
      <Divider sx={{ ml: 3, mt: 2 }} />
      <Stack divider={<Divider />} spacing={2} ml={3} mt={2}>
        {pack.items.map((item, index) => (
          <Stack key={index}>
            <ItemCollapseHeader
              title={`#${index + 1} ${item.description}`}
              hasBattery={itemsBatteryPresent[index]}
              formCompleted={!!batteryInspectionFormData.batteryFormsData[index]}
            />
            <MuiCollapse in={index === openFormIndex}>
              <BatteryForm
                initialFormData={{ itemId: item.ebayItemId, itemDescription: item.description }}
                onChange={(formData) => handleFormChange(index, formData)}
                onSubmit={(formData) => handleFormSubmit(index, formData)}
              />
            </MuiCollapse>
          </Stack>
        ))}
        {areAllItemFormsCompleted && (
          <>
            {hasPackageBattery && (
              <YesNoSelect
                label="Is the parcel equipped with an effective means of preventing accidental activation?"
                value={batteryInspectionFormData.doesPreventAccident}
                onChange={(_, v) =>
                  setBatteryInspectionFormData({
                    ...batteryInspectionFormData,
                    doesPreventAccident: v,
                  })
                }
              />
            )}
            <Button
              variant="contained"
              sx={{ height: 45 }}
              onClick={() =>
                currentInspection
                  ? classifyBattery(batteryInspectionFormData as BatteryInspectionFormData)
                  : submitBatteryInspection(batteryInspectionFormData as BatteryInspectionFormData)
              }
              disabled={hasPackageBattery && !isPreventAccidentFieldSet}
            >
              submit
            </Button>
          </>
        )}
      </Stack>
    </div>
  )
}
