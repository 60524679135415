import { Button } from 'easyship-components'
import { Exclaim } from 'easyship-components/icons'

import { useCourierReturnInspectionMutation } from '@/CourierReturn/useCourierReturnInspectionMutation'

import { usePackage } from './PackageProvider'

interface ActionButton {
  label: string
  isVisible: boolean
  callback: () => void
  color: 'primary' | 'danger' | 'default' | undefined
}
interface MessageProps {
  message: string
}

function MessageWithExclaim({ message }: MessageProps) {
  return (
    <div className="mb-3 flex items-center">
      <Exclaim className="mr-2 fill-red-900" />
      <div className="text-xl font-bold text-ink-500">{message}</div>
    </div>
  )
}

export default function PackageActions() {
  const { pack, bin, markForDestruction, markForLiquidation, requestInspection } = usePackage()
  const { mutate: mutateCourierReturnInspection, isLoading } = useCourierReturnInspectionMutation()

  if (bin) return null

  const isReadyForDestroyLiquidate = pack.warehouseState.value === 'ready_for_destroy_liquidate'
  const isCourierReturned = pack.warehouseState.value === 'courier_returned'
  const hasBatteryRestriction = pack.courierReturn?.returnReason === 'battery_restriction'
  const hasDgPgRestriction = pack.courierReturn?.returnReason === 'dg_pg_restriction'

  const showLiquidateButton =
    ['ready_for_liquidate_dps', 'ready_for_destroy_liquidate'].includes(
      pack.warehouseState.value,
    ) && !pack.courierReturn?.contentDamaged

  const showToBeInspectedButton =
    isCourierReturned &&
    (hasBatteryRestriction || hasDgPgRestriction) &&
    !!pack.courierReturn?.processed_at

  const shouldShowReshopMessage =
    isReadyForDestroyLiquidate && pack.courierReturn?.label && !pack.courierReturn.label.active

  const shouldShowInspectionMessage =
    isCourierReturned &&
    pack.courierReturn?.processed_at &&
    (hasBatteryRestriction || hasDgPgRestriction)

  function getInspectionMessage() {
    if (hasBatteryRestriction) {
      return 'No Previous Battery Inspection Result'
    } else if (hasDgPgRestriction) {
      return 'No Previous DG/PG Inspection Result'
    }
    return ''
  }

  const actionButtons: ActionButton[] = [
    {
      label: 'request inspection',
      isVisible: ['ready_for_xray', 'xray_completed'].includes(pack.warehouseState.value),
      callback: () => requestInspection('xray'),
      color: 'default',
    },
    {
      label: 'destroy',
      isVisible: isReadyForDestroyLiquidate,
      callback: markForDestruction,
      color: 'default',
    },
    {
      label: 'liquidate',
      isVisible: showLiquidateButton,
      callback: markForLiquidation,
      color: 'default',
    },
    {
      label: 'To Be Inspected',
      isVisible: showToBeInspectedButton,
      callback: () =>
        mutateCourierReturnInspection({
          packageId: pack.id,
          courierReturnId: pack.courierReturn?.id as string,
        }),
      color: 'default',
    },
  ]

  const actionButtonsVisible = actionButtons.filter((actionButton) => actionButton.isVisible)

  if (actionButtonsVisible.length === 0) return null

  return (
    <>
      {shouldShowReshopMessage && <MessageWithExclaim message="Cannot Be Reshopped" />}
      {shouldShowInspectionMessage && <MessageWithExclaim message={getInspectionMessage()} />}
      <div
        className={`flex flex-wrap gap-y-6 ${
          actionButtonsVisible.length === 1 ? 'justify-center' : 'justify-between'
        }`}
      >
        {actionButtonsVisible.map((actionButton) => (
          <div key={actionButton.label} className="max-w-[48%] flex-[48%]">
            <Button
              onClick={actionButton.callback}
              color={actionButton.color}
              className="h-[80px] w-full"
              loading={actionButton.label === 'To Be Inspected' && isLoading}
            >
              {actionButton.label}
            </Button>
          </div>
        ))}
      </div>
    </>
  )
}
