import Chance from 'chance'

import PreInspectionPackage from '@/preInspection/models/PreInspection'

const chance = new Chance()

export const preInspectionPackageStub = (): PreInspectionPackage => ({
  id: chance.string(),
  easyshipShipmentId: 'EEUS000003901GB',
  items: [
    {
      ebayItemId: chance.string(),
      quantity: 1,
      listingUrl: 'url',
      description: 'Blast off body spray Rolex Watch light bluish black gold',
      sku: 1,
    },
  ],
  screeningFlags: [
    { category: 'battery', id: 12, keyword: 'bodyspray' },
    { category: 'dangerous', id: 13, keyword: 'batteries' },
  ],
})
